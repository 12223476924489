.nav-container {
  height: 75px;
  background-color: #fff;
  border-bottom: 1px solid #deddd6;
}

.nav {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  margin: 0px 15px;
}

.nav-left {
  display: flex;
  align-items: center;
  gap: 40px;
}

.nav-left > a {
  cursor: pointer;
  color: #2c5e1a;
}

.nav-links > a {
  color: #2c5e1a;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
}

.nav-right {
  display: flex;
  align-items: center;
}

#login-button {
  background-color: #ededec;
  padding: 10px 20px;
  border-radius: 20px;
  border: none;
}

#login-button > a {
  color: #000;
}

.profile-button {
  background-color: #fff;
  border: none;
  cursor: pointer;
}

.profile-pic-container.navi {
  position: relative;
  width: fit-content;
  border-radius: 60px;
  height: 55px;
  overflow: hidden;
}


.profile-pic.navi {
  height: 55px;
  width: 55px;
}

.profile-dropdown {
  position: absolute;
  z-index: 2;
  list-style-type: none;
  top: 65px;
  right: 25px;
  width: auto;
  min-width: 200px;
  height: auto;
  min-height: 100px;
  font-size: 1.2rem;
  background-color: #fff;
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
  border-radius: 24px 24px 24px 24px;
}

.dropdown-options{
  padding: 12px;
  box-sizing: border-box;
}

.dropdown-username,
.dropdown-email,
.dropdown-button {
  width: 100%;
}

.dropdown-button {
  cursor: pointer;
  font-weight: 800px;
}

.dropdown-button:hover {
  background-color: #efefec;
}

.hidden {
  display: none;
}
