/*------ General CSS ------*/

html {
  scroll-behavior: smooth;
}

html,
body {
  font-family: "Manrope", sans-serif;
  width: 100%;
  height: 100%;
  color: #2b381f;
}

.primary-color{
  color: #2b381f;
}

.secondary-color {
  color: #6d7667;
}

textarea,
input {
  font-family: "Manrope", sans-serif;
  font-size: 1.1rem;
  width: 100%;
}

body {
  margin: 0px;
}

h2,
h3,
h4 {
  color: #6d7667;
  margin: 0px;
}

h1 {
  color: #2b381f;
  margin: 0px;
}

p {
  margin: 0;
  color: #2b381f;
}

.header-divider {
  border: 1px solid #e2e1db;
}

.item-divider {
  margin: 17px 0;
  border: 1px solid #e2e1db;
}

/*------ nuttons ------*/

.white-button {
  cursor: pointer;
  height: 50px;
  width: 100px;
  border: 1px solid #faf9f6;
  border-radius: 25px;
  font-size: 1rem;
  font-weight: 600;
  background-color: #fff;
}

.white-button:hover {
  border: none;
  background-color: #efefec;
}

.green-button {
  cursor: pointer;
  height: 50px;
  width: 100px;
  border: 1px solid #faf9f6;
  border-radius: 25px;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  background-color: #2c5e1a;
}

.green-button:hover {
  background-color: #265016;
}

.grey-button {
  height: 50px;
  width: 100px;
  border: 1px solid #faf9f6;
  border-radius: 25px;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  background-color: hsl(0, 0%, 61%);
  cursor: pointer;
}

.green-button.disabled {
  color: #efefeb;
  background-color: #fafaf9;
}

/*------ modals ------*/
.modal-header {
  color: #2b381f;
}

/* .modal-subheader {
} */

/*------ stars ------*/

.fa-star {
  color: #5de372;
}

/* things that need to fit at the far right of a container */

.postion-end {
  align-self: center;
  margin-left: auto;
}
