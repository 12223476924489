.list-details-container{
    height: 95vh;
}


.list-details-content{
    display: flex;
    width: 100%;
    height: 100%;
}

.list-details-content-left{
    border-right: 2px solid #dddcd5;
    width: 15%;
}

.list-details-content-right{
    height: 100%;
    width: 90%;
}

.list-details-list-title{
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    height: 70px;
    box-shadow: 0px 10px 10px #b8b8b5;
    margin-bottom: 20px;
}

#list-details-title{
    color: #2b381f;
    font-size: 1.4rem;
    font-weight: 400;
}

.list-details-ptags{
    display: flex;
    justify-content: space-between;
}

#list-details-len{
    margin-top: auto;
    vertical-align: bottom;
}

.list-details-bookmarks{
    overflow-y: auto;
    height: 89vh;
}

.no-bookmarks{
    margin-top: 200px;
    text-align: center;
}

#explorer-trails-button{
    margin-top: 20px;
    font-size: 1rem;
    font-weight: 600;
    width: 150px;
}
.list-details-edit{
    cursor: pointer;
}
.list-details-edit:hover{
    text-decoration: underline;
}