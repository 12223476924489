.new-review-modal{
    color: #2B381F;
    min-width: 300px;
    max-height: 700px;
    width: 400px;
    padding: 20px;
    border-radius: 20px;
    background-color: #fff;
}


.new-review-rating-input {
    display: flex;
    align-content: center;
    gap: 5px;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .new-review-rating-container{
    margin-bottom: 20px;
  }

  .text-area.review {
    resize: none;
    height: 150px;
    padding: 10px;
    box-sizing: border-box;
    font-size: 1rem;
  }

  .green-button.review{
    margin-top: 10px;
  }
  