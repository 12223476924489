.profile-user-lists {
    height: 800px;
    width: 700px;
    padding: 30px 20px;
  }
  
  .list-container.profile {
    overflow-y: auto;
    padding: 10px;
    margin-bottom: 5px;
    max-height: 775px;
  }
  
  .trash-can {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    padding: 12px;
    border-radius: 30px;
    color: #000;
    cursor: pointer;
  }
  
  .trash-can:hover {
    background-color: #efefec;
  }