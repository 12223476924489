.forecast-container {
  justify-content: center;
  gap: 30px;
  display: flex;
}

.forecast-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.forecast-day {
  color: #2b381f;
  font-weight: 600;
  font-size: 1.2rem;
}

.forecast-desc,.forecast-temp {
  color: #6d7667;
  font-weight: 500;;
  font-size: .9rem;
}

