.logoContainer {
    display: flex;
    align-items: center;
  }

  .logo {
    height: 45px;
    width: 44px;
    margin: 5px;
  }
  
  .title {
    font-weight: 600;
    font-size: 2.0rem;
    margin: 0;
    color: #2c5e1a;
    text-decoration: none;
  }

  a:link {
    text-decoration: none;
  }
