.bookmark-list-modal {
  color: #2B381F;
  min-width: 300px;
  max-height: 700px;
  width: 400px;
  padding: 20px;
  border-radius: 20px;
  background-color: #fff;
}

.x-mark{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  padding: 5px;
  margin-bottom: 7px;
  border-radius: 20px;
}

.x-mark:hover{
  background-color: #efefec;
}

.create-list{
  display: flex;
  align-items: center;
  gap: 10px;
  vertical-align: bottom;
  cursor: pointer;
  padding: 10px;
}

.create-list>p{
  font-size: 1.2rem;
}

.bookmark-list-container.modal{
  overflow-y: auto;
  padding: 10px;
  margin-bottom: 5px;
  max-height: 400px;
}

.bookmark-list-container::-webkit-scrollbar {
  width: .1em;
  padding: 5px;
}
 
.bookmark-list-container::-webkit-scrollbar-track {
  padding: 5px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
.booomark-list-container::-webkit-scrollbar-thumb {
  padding: 5px;
  background-color: #efefeb;
  outline: 1px solid #5a5a5a;
}

.plus-sign{
  height: 15px;
  width: 15px;
  padding: 12px;
  border-radius: 30px;
  background-color: #efefec;
}

.bookmark-item{
  display: flex;
  gap: 20px;
}

.bookmark-tab{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  padding: 12px;
  border-radius: 30px;
  color: #000;
  background-color: #efefec;
}


.title-field{
  display: flex;
}
