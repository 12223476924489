.review-item-options{
    display: flex;
    margin-top: 10px;
    gap: 10px;
}

.profile-pic.review {
    height: 55px;
    width: 55px;
  }
  
  .profile-pic-container.review {
    position: relative;
    width: fit-content;
    border-radius: 60px;
    height: 55px;
    overflow: hidden;
  }
  
  .trail-details-reviews-container {
    color: #464e37;
  }

  .trail-details-review-info{
   display: flex;
   justify-content: space-between;
  }
  
  .user-follow-button{
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    height: 35px;
    width: 35px;
    border: none;
    border-radius: 20px;
    background-color: transparent;
  }

  .user-follow-button:hover{
    background-color: #efefec;
  }
  
  .trail-details-review-rating {
    margin-bottom: 5px;
  }
  
  .trail-details-review-info {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  #review-user-name {
    font-family: 600;
  }
  
  #review-date {
    color: #6d7667;
    font-weight: 200;
    font-size: 0.8rem;
  }
  

#delete-option,#edit-option{
    color: #2B381F;
    font-size: 1.1rem;
    text-decoration: underline;
    cursor: pointer;
}

